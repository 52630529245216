import { createApp } from 'vue';
import { createPinia } from 'pinia';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import i18n from './i18n/i18n';
import App from './App.vue';
import VueMobileDetection from 'vue-mobile-detection';
import router from './routes/index';
//import GoogleSignInPlugin from "vue3-google-signin"
import vue3GoogleLogin from 'vue3-google-login';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import { gaScreenAppViewEvent, loadGtagScript } from './assets/tools/GoogleAnalyticsEvent';
import serverConfig from './assets/network/serverConfig';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import { initCustomerIntercom } from './assets/tools/CustomerType';
import VueLuckyCanvas from '@lucky-canvas/vue';

initCustomerIntercom();

loadGtagScript(serverConfig.googleAnalyticsId, () => {
  gaScreenAppViewEvent();
});

const currentDomain = window.location.hostname;
const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.use(createPinia());
app.use(router);
app.use(VueMobileDetection);
app.use(VueReCaptcha, { siteKey: currentDomain.includes('www.wingame.com') ? process.env.VUE_APP_GOOGLE_KEY2 : process.env.VUE_APP_GOOGLE_KEY });

app.use(vue3GoogleLogin, {
  clientId: currentDomain.includes('www.wingame.com') ? process.env.VUE_APP_CLIENT_ID2 : process.env.VUE_APP_CLIENT_ID
});
app.use(ElementPlus);
app.use(i18n).mount('#app');
app.use(VueLuckyCanvas).mount('#myLucky');
