<!-- eslint-disable no-unused-vars -->
<script setup>
import { ref, watch, defineExpose } from 'vue';
import Luckywheel from '@/components/LuckyWheel/Luckywheel.vue';
import LuckywheelItem from '@/components/LuckyWheel/LuckywheelItem.vue';
import iconHp from '@/assets/images/spin/icon_hp.webp'
defineOptions({
    name: 'Turntable'
})
const bgColorList = [
    'linear-gradient(60deg, #407DFF 20%, #18A4FF 30%)',
    'linear-gradient(60deg, #4379FF 20%, #2795FF 30%)',
    'linear-gradient(60deg, #4874FF 20%, #427AFF 30%)',
    'linear-gradient(60deg, #5865FF 20%, #7F3FFF 30%)',
    'linear-gradient(60deg, #5A62FF 20%, #922DFF 30%)',
    'linear-gradient(60deg, #5964FF 20%, #8F30FF 30%)',
    'linear-gradient(60deg, #5667FF 20%, #7E40FF 30%)',
    'linear-gradient(60deg, #4F6EFF 20%, #635AFF 30%)',
    'linear-gradient(60deg, #4775FF 20%, #3E7FFF 30%)',
    'linear-gradient(60deg, #407DFF 20%, #18A4FF 30%)',
    'linear-gradient(60deg, #407DFF 20%, #18A4FF 30%)',
]

const emits = defineEmits(['beforeStartCallback', 'endCallback'])

const props = defineProps({
    sections: {
        type: [Array],
        default: () => []
    },
});

const prizeIndex = ref(0)
const prizes = ref([])

// 监听 sections 的变化
watch(() => props.sections, (val) => {
    prizes.value = []
    prizes.value = val.map((item, index) => {
        return {
            text: item,
            background: item.reward_type === '1' ? bgColorList[index % 12] : 'linear-gradient(60deg, #FF4011 20%, #FF8E18 30%)',
            ...item
        }
    });
}, {
    immediate: true,
    deep: true
})

// 创建对抽奖组件的引用
const myLucky = ref(null)


// 点击抽奖按钮会触发star回调
const startCallback = () => {
    console.log('开始')
    emits('beforeStartCallback')
}

// 抽奖结束会触发end回调
const endCallback = () => {
    emits('endCallback', prizes.value[prizeIndex.value])
}

const play = (index) => {
    prizeIndex.value = index
    myLucky.value.draw()
};

const stop = (index) => {
    // myLucky.value.stop(index)
};


const init = () => {
    myLucky.value.init()
};




defineExpose({
    play,
    stop,
    init
});


</script>

<template>
    <div class="flex justify-center">
        <div id="myLucky" class="relative">
            <img class="absolute top-[0px] right-[50%] w-[3rem] z-50"
                style="right: 50%; transform: translateX(50%) rotate(-90deg);"
                src="../../../assets//images/spin/zp_02.png" alt="">\

            <!-- <LuckyWheel ref="myLucky" width="21rem" height="21rem" :prizes="prizes" :blocks="blocks" :buttons="buttons"
                @start="startCallback" @end="endCallback" /> -->
            <Luckywheel ref="myLucky" :prize-index="prizeIndex" @get-prize="startCallback" @game-over="endCallback"
                :total="prizes.length">
                <LuckywheelItem v-for="(prize, index) in prizes" :key="index" :total="prizes.length" :index="index"
                    :bgColor="prize.background">
                    <div class="flex !rotate-[270deg] transform">
                        <span class="text-[#fff] text-[1rem] mr-[10px] font-medium">{{ prize.text }}</span>
                        <img :src="iconHp" alt="" class="w-[1.6rem]" />
                    </div>
                </LuckywheelItem>
            </Luckywheel>
        </div>
    </div>
</template>

<style scoped>
@tailwind base;

.rotate-90 {
    transform: rotate(90deg);
}
</style>
