<template>
  <div :class="topClass" class="overflow-hidden max-w-screen" id="top_class">
    <!--    <my-i18n/>-->
    <router-view></router-view>

    <!-- button class="win-game-style" @click="showPfList = true">

    </button>
    <button v-show="showPfList" class="win-game-style" :style="'background-color:'+pf.bgColor+';bottom:' + (11 + (index * 2)) +'rem;'" @click="loadPf(pf.style)" v-for="(pf,index) in pfList" :key="index">

    </button -->
  </div>
  <launch-loading v-if="!isLaunchLoaded"></launch-loading>
</template>
<script setup>
import './assets/css/common.css';
import './assets/css/switch.css';
import './assets/css/switch960.css';
import './assets/fonts/fonts.css';
import launchLoading from './components/loading/launchLoading.vue';

import { getCookieAccessToken, getCookieUserId, getCookiesWinGameStyle, setCookiesWinGameStyle } from '@/assets/tools/CommonTool';
const addMaximumScaleToMetaViewport = () => {
  const el = document.querySelector('meta[name=viewport]');

  if (el !== null) {
    let content = el.getAttribute('content');
    let re = /maximum\\-scale=[0-9\\.]+/g;

    if (re.test(content)) {
      content = content.replace(re, 'maximum-scale=1.0');
    } else {
      content = [content, 'maximum-scale=1.0'].join(', ');
    }

    el.setAttribute('content', content);
  }
};

const disableIosTextFieldZoom = addMaximumScaleToMetaViewport;

// 检测设备是否为iOS
const checkIsIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

if (checkIsIOS()) {
  disableIosTextFieldZoom();
}
</script>
<script>
import serverConfig from '@/assets/network/serverConfig';
import { setFingerPrintInfo } from './assets/tools/CommonTool';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { useAppStore } from './store/store';
import { userInfoService, updateUserFBPixelIdService, kwaiEventService } from './assets/network/service/user';
import { fpInit } from './assets/tools/FacebookPixelEvent';
// import { getLocalPre } from './routes/index.js';
import { fpPCustomEvent } from '@/assets/tools/FacebookPixelEvent';
import { initAdjust } from '@/assets/tools/adjustConfig'
import emitter from '@/assets/tools/eventBus';

export default {
  name: 'App',
  components: {
    // myI18n
  },
  watch: {
    $route(to) {
      if (to.name === 'download' || to.name === 'download1') {
        this.isLaunchLoaded = true;
      }
      window.scrollTop = 0;
      if (this.topClass == 'web web960') {
        setTimeout(this.resize, 500);
      }
      let body = document.getElementsByTagName('body');
      if (body) {
        body[0].classList.remove('hidden-overflow-y');
      }
    },
    isLogin(newVal) {
      console.log(newVal,'isLogin')
      if (newVal) {
        
        emitter.emit('custom-showSpin')
      }
    }
  },
  computed: {
    isLogin() {
      return this.appStore.isLogin;
    }
  },
  data() {
    return {
      pfList: [
        { code: '默认', style: 'default', bgColor: '#0c131d' },
        { code: '深空灰', style: 'skh', bgColor: '#192639' },
        { code: '浅色', style: 'qs', bgColor: '#e7ebec' }
      ],
      showPfList: false,
      topClass: 'web',
      first: true,
      isLaunchLoaded: false,
      isPwa: false,
      appStore: {}
    };
  },
  mounted() {
    this.topClass = this.$isMobile() ? 'phone' : 'web';
    if (window.innerWidth <= serverConfig.breakPx[1]) {
      this.topClass = 'phone';
    } else if (window.innerWidth <= serverConfig.breakPx[0]) {
      this.topClass = 'web web-960';
    }
    this.resize();
    // this.disableZoomOnInputFocus()

    var style = document.createElement('style');
    style.type = 'text/css';
    // 添加样式规则
    style.innerHTML = '.body {  } .body2{}';
    // 将style标签添加到head标签中
    document.getElementsByTagName('head')[0].appendChild(style);

    setTimeout(() => {
      this.isLaunchLoaded = true;
    }, 2000);
    this.appStore = useAppStore();
  },
  created() {
    this.fbPixelInit()

    // let locale = localStorage.getItem('locale');
    // if (locale) {
    //   if (locale == 'zh') {
    //     locale = 'en';
    //     this.$i18n.locale = locale;
    //     localStorage.setItem('locale', locale);
    //     this.fbPixelInit();
    //   } else {
    //     this.$i18n.locale = locale;
    //     this.fbPixelInit();
    //   }
    // } else {
    //   getLocalPre(this.fbPixelInit, {});
    // }
    window.addEventListener('resize', this.resize);
    var style = document.createElement('style');
    style.type = 'text/css';
    // 添加样式规则
    style.innerHTML = '.body { }';

    document.getElementsByTagName('head')[0].appendChild(style);

    // 在组件创建时获取浏览器指纹
    FingerprintJS.load()
      .then((fp) => fp.get())
      .then((result) => {
        console.log('FingerprintJS visitorId:' + result.visitorId);
        setFingerPrintInfo(result.visitorId);
      })
      .catch((error) => console.error('FingerprintJS error:' + error));
    this.loadPf();

    initAdjust();
  },
  methods: {
    // 或者使用以下方法创建CSS选择器
    createCSSSelector(selector, style) {
      var styleSheet = document.styleSheets[document.styleSheets.length - 1];
      if (styleSheet.insertRule) {
        console.log('insert');
        styleSheet.insertRule(selector + '{' + style + '}', styleSheet.cssRules.length);
      } else if (styleSheet.addRule) {
        console.log('add');
        styleSheet.addRule(selector, style);
      }
    },
    loadPf(changeWinGameStyle) {
      this.showPfList = false;
      if (changeWinGameStyle) {
        setCookiesWinGameStyle(changeWinGameStyle || 'default');
        window.location.reload();
        return;
      }
      let winGameStyle = getCookiesWinGameStyle();

      if (winGameStyle == 'qs') {
        document.documentElement.style =
          '--color-8ea3c3:#8ea3c3;' +
          '--input-error-border-color:#7D1A2B;' +
          '--error-tip-color:#c25156;' +
          '--error-tip-bg-color:#273854;' +
          '--login-with-bg-color:#273854;' +
          '--header-menu-bg-color:#1E2736;' +
          '--children-bg-color:#1e2736;' +
          '--header-bg-color:#24396B;' +
          '--menu-header-bg-color:none;' +
          '--input-default-border-color:#2B3A60;' +
          '--input-default-bg-color:#E7EBEC;' +
          '--menu-bg-color:#2e4271;' +
          '--body-bg-color:#e7ebec;' +
          '--foot-body-bg-color:#24396b;' +
          '--menu-item-bg-color:#4763A4;' +
          '--menu-item-color:#FFFFFF;' +
          '--menu-item-locale-color:#FFF;' +
          '--menu-item-selected-color:#2E4271;' +
          '--menu-item-selected-bg-color:#D3E4FF;' +
          '--menu-item-children-bg-color:#466089;' +
          '--menu-item-line-color:#2b3a60;' +
          '--money-unit-color:#AD8137;' +
          '--money-color:#0F1721;' +
          '--input-color:#55657E;' +
          '--winer-list-tabs-bg:#d3e4ff;' +
          '--white-color:#FFFFFF;' +
          '--profit-bg-color:#e7ebec;' +
          '--profit-single-bg-color:#d3ebff;' +
          '--bg-blue-color:#1184fa;' +
          '--green-400:#00D66F;' +
          '--positive-colors:var(--green-400);' +
          '--positive-colors-box:#067546;' +
          '--no-positive-colors:#D68000;' +
          '--no-positive-colors-box:#714A0F;' +
          '--home-banner-bg-color:#4763a4;' +
          '--home-banner-bg2-color:#c1d5f6;' +
          '--tab-bg-color:#d3e4ff;' +
          '--tab-color:#2B3A60;' +
          '--tab-selected-bg-color:#222942;' +
          '--tab-selected-color:#FFFFFF;' +
          '--game-list-desc-color:#2B3A60;' +
          '--game-list-text-color:#2B3A60;' +
          '--send-code-btn:#273854;' +
          '--default-btn-bg-color:#c1d5f6;' +
          '--default-btn-color:#FFFFFF;' +
          '--profile-info-color:#8EA3C3;' +
          '--money-btn-bg-color:#e7ebec;' +
          '--icon-bg-color:#2B3A60;' +
          '--profile-symbol-bg-color:#2C394E;' +
          '--btn-cb-1-bg-color:#C1D5F6;' +
          '--btn-cb-1-bg-path-color:#2B3A60;' +
          '--dialog-title-color:#FFFFFF;' +
          '--login-tab-bg-color:#30405b;' +
          '--load-more-bg-color:#4763a4;' +
          '--footer-color:#7E95B7;' +
          '--footer-color2:#7E95B7;' +
          '--footer-color3:#7E95B7;' +
          '--profit-item-color:#2B3A60;';
      } else if (winGameStyle == 'skh') {
        document.documentElement.style =
          '--color-8ea3c3:#8ea3c3;' +
          '--input-error-border-color:#7D1A2B;' +
          '--error-tip-color:#c25156;' +
          '--error-tip-bg-color:#273854;' +
          '--login-with-bg-color:#273854;' +
          '--header-menu-bg-color:#1E2736;' +
          '--children-bg-color:#1e2736;' +
          '--header-bg-color:#233142;' +
          '--menu-header-bg-color:none;' +
          '--input-default-border-color:#16202C;' +
          '--input-default-bg-color:#0f1721;' +
          '--menu-bg-color:#16202F;' +
          '--body-bg-color:#192639;' +
          '--foot-body-bg-color:#192639;' +
          '--menu-item-bg-color:#233142;' +
          '--menu-item-color:#FFFFFF;' +
          '--menu-item-locale-color:#FFF;' +
          '--menu-item-selected-color:#FFF;' +
          '--menu-item-selected-bg-color:#466089;' +
          '--menu-item-children-bg-color:#466089;' +
          '--menu-item-line-color:#2c3d4d;' +
          '--money-unit-color:#D0A358;' +
          '--money-color:#55657E;' +
          '--input-color:#55657E;' +
          '--winer-list-tabs-bg:#30405b;' +
          '--white-color:#FFFFFF;' +
          '--profit-bg-color:#233142;' +
          '--profit-single-bg-color:#30405b;' +
          '--bg-blue-color:#1184fa;' +
          '--green-400:#00D66F;' +
          '--positive-colors:var(--green-400);' +
          '--positive-colors-box:#067546;' +
          '--no-positive-colors:#D68000;' +
          '--no-positive-colors-box:#714A0F;' +
          '--home-banner-bg-color:#233142;' +
          '--home-banner-bg2-color:#2C394E;' +
          '--tab-bg-color:#30405b;' +
          '--tab-color:#96AED3;' +
          '--tab-selected-bg-color:#222942;' +
          '--tab-selected-color:#FFFFFF;' +
          '--game-list-desc-color:#96AED3;' +
          '--game-list-text-color:#FFFFFF;' +
          '--send-code-btn:#273854;' +
          '--default-btn-bg-color:#466089;' +
          '--default-btn-color:#FFFFFF;' +
          '--profile-info-color:#8EA3C3;' +
          '--money-btn-bg-color:#192639;' +
          '--icon-bg-color:#96AED3;' +
          '--profile-symbol-bg-color:#2C394E;' +
          '--btn-cb-1-bg-color:#466089;' +
          '--btn-cb-1-bg-path-color:#697e9f;' +
          '--dialog-title-color:#FFFFFF;' +
          '--login-tab-bg-color:#30405b;' +
          '--load-more-bg-color:#466089;' +
          '--footer-color:#96AED3;' +
          '--footer-color2:#697E9F;' +
          '--footer-color3:#697E9F;' +
          '--profit-item-color:#96AED3;';
      } else if (!winGameStyle || winGameStyle == 'default') {
        document.documentElement.style =
          '--color-8ea3c3:#8ea3c3;' +
          '--color-55657E:#55657E;' +
          '--scrollbar-color:#55657E;' +
          '--search-border-color:#55657E;' +
          '--referral_level2-desc-color:#55657E;' +
          '--input-error-border-color:#7D1A2B;' +
          '--error-tip-color:#c25156;' +
          '--error-tip-bg-color:#273854;' +
          '--login-with-bg-color:#273854;' +
          '--header-menu-bg-color:#1E2736;' +
          '--children-bg-color:#1e2736;' +
          '--header-bg-color:#16202C;' +
          '--menu-header-bg-color:#16202C;' +
          '--input-default-border-color:#16202C;' +
          '--input-default-bg-color:#000000;' +
          '--menu-bg-color:#16202C;' +
          '--body-bg-color:#0c131d;' +
          '--foot-body-bg-color:#0c131d;' +
          '--menu-item-bg-color:#1e2736;' +
          '--menu-item-color:#FFF;' +
          '--menu-item-locale-color:#8ea3c3;' +
          '--menu-item-selected-color:#FFF;' +
          '--menu-item-selected-bg-color:#273854;' +
          '--menu-item-children-bg-color:#2c394e;' +
          '--menu-item-line-color:#55657E;' +
          '--money-unit-color:#D0A358;' +
          '--money-color:#55657E;' +
          '--input-color:#55657E;' +
          '--winer-list-tabs-bg:#0C131D;' +
          '--white-color:#FFFFFF;' +
          '--profit-bg-color:#16202C;' +
          '--profit-single-bg-color:#1e2736;' +
          '--bg-blue-color:#1184fa;' +
          '--green-400:#00D66F;' +
          '--positive-colors:var(--green-400);' +
          '--positive-colors-box:#067546;' +
          '--no-positive-colors:#D68000;' +
          '--no-positive-colors-box:#714A0F;' +
          '--home-banner-bg-color:#16202c;' +
          '--home-banner-bg2-color:#2C394E;' +
          '--tab-bg-color:#1e2736;' +
          '--home-tab-color:#FFFFFF;' +
          '--tab-color:#55657E;' +
          '--tab-selected-bg-color:#222942;' +
          '--tab-selected-color:#FFFFFF;' +
          '--game-list-desc-color:#8EA3C3;' +
          '--game-list-text-color:#FFFFFF;' +
          '--send-code-btn:#273854;' +
          '--default-btn-bg-color:#273854;' +
          '--default-btn-color:#FFFFFF;' +
          '--profile-info-color:#8EA3C3;' +
          '--money-btn-bg-color:#0d0f13;' +
          '--icon-bg-color:#8EA3C3;' +
          '--profile-symbol-bg-color:#2C394E;' +
          '--btn-cb-1-bg-color:#273854;' +
          '--btn-cb-1-bg-path-color:#55657E;' +
          '--dialog-title-color:#FFFFFF;' +
          '--login-tab-bg-color:#1e2736;' +
          '--load-more-bg-color:#273854;' +
          '--footer-color:#96AED3;' +
          '--footer-color2:#55657E;' +
          '--footer-color3:#FFFFFF;' +
          '--profit-item-color:#8EA3C3;' +
          '--help_content_left_selected_color:#1184fa;' +
          '--help_content_phone_menu-bg-color:#273854;' +
          '--phone_help-item-color: #1184fa;' +
          '--phone_help-top-title-bg-color: #16202C;' +
          '--help_content_line-bg-color:#2C394E;' +
          '--term_of__title_color:#E0E6EE;' +
          '--tran-my-beats-record-title-color:#ACB3D7;' +
          '--referral-bg-color: #1e2736;' +
          '--referral-data-tab-bg-color:#0C131D;' +
          '--gold-color:#FFD233;' +
          '--referral_level-code-link-bg-color:#2C394E;' +
          '--active-sub-agents-right-line:#3A3E53;' +
          '--deposit-withdraw-select-color:#FFFFFF;' +
          '--deposit-withdraw-select-border-color:#55657E;' +
          '--deposit-withdraw-transaction-bg-color:#55657E;' +
          '--deposit-symbol-block-border-color:#FFFFFF;' +
          '--deposit-symbol-unselected-block-border-color:#1e2736;' +
          '--deposit-withdraw-selected-div-bg-color:#FFFFFF;' +
          '--deposit-withdraw-block-bg-color:#2C394E;' +
          '--my-dialog-main-bg-color: #1e2736;' +
          '--my-dialog-out-main-bg-color:#00000080;' +
          '--deposit-title-color: #ACB3D7;' +
          '--brl-deposit-symbol-bg-color: #55657E;' +
          '--pin-setting-note-color: #D0A358;' +
          '--no-data-color:#6D728D;' +
          '--btn-disable-bg-color:#0a3969;' +
          '--btn-disable-color:#55657e;' +
          '--brl-input-color: #FFFFFF;' +
          '--brl-refresh-icon-bg-color: #43A0FF;' +
          '--agree-circle-bg-color:#1CA45f;' +
          '--agree-circle-un-selected-bg-color:#0C0D11;' +
          '--agree-circle-un-selected-border-color:#3A3E53;' +
          '--page-title-color:#FFF;' +
          '--amount-text-other-color:#6D728D;' +
          '--amount-text-color:#FFFFFF;' +
          '--brl-balance-block-color: #FFFFFF;' +
          '--brl-input-money-unit: #FFFFFF;' +
          '--brl-line-bg-color: #1E2736;' +
          '--add-pix-select-div-main-bg-color:#000000;' +
          '--add-pix-select-div-main-border-color:#55657E;' +
          '--green-color:#00D66F;' +
          '--login-privacy-color:#55657E;' +
          '--search-input-border-color:#55657E;' +
          '--login-reset-input-border-color:#1e273d;' +
          '--search-history-bg-color:#16202C;' +
          '--slots_sort-bg-color:#1e2736;' +
          '--winner-bar-title-right-bg-color:#273854;' +
          '--rtp-color:#0C131D;' +
          '--rtp-bg-color:#8ea3c3;' +
          '--phone-login-dialog-bg-color:#16202c;' +
          '--carousel__next-or-pre-color:#e8e5e5;' +
          '--footer-menu-selected-color:#FFFFFF;' +
          '--game-detail-btn-color:#FFFFFF;' +
          '--game-detail-money-color:#FFFFFF;' +
          '--game-detail-desc-color:#FFFFFF;' +
          '--game-detail-desc-color2:#00D66F;' +
          '--game-detail-play_btn-try-bg-color:#273854;' +
          '--game-detail-bottom-bg-color:#191F33;' +
          '--game-detail-bottom-favorites-color:#ACB3D7;' +
          '--game-detail-bottom-favorites-line-color:#2C394E;' +
          '--game-detail-open-fun-btn-bg-color:#1184fa;' +
          '--game-detail-close-fun-btn-bg-color:#3a3e53;' +
          '--game-detail-switch-fun-btn-bg-color:#FFFFFF;' +
          '--page-default-color:#2c3e50;' +
          '--referral-answer-color:#55657E;' +
          '--some_examples-bottom-line-color:#2C394E;' +
          '--affiliateProgram-commission-color-1:#16202c00;' +
          '--affiliateProgram-commission-color-2:#1e2736;' +
          '--affiliateProgram-commission-color-3:#1f2938;' +
          '--affiliateProgram-commission-color-4:#232e4000;' +
          '--affiliateProgram-why-us-bg-color: #1e2736;' +
          '--affiliateProgram-why-us-title-color: #fff;' +
          '--affiliateProgram-why-us-desc-color: #8EA3C3;' +
          '--affiliateProgram-circle-level-color: #FFFFFF;' +
          '--affiliateProgram-circle-percent-color: #FFFFFF;' +
          '--affiliateProgram-circle-subtitle-color: #8EA3C3;' +
          '--affiliateProgram-circle-diagram-c1-bg-color:#2C394E;' +
          '--affiliateProgram-circle-diagram-c2-bg-color:#D0A358;' +
          '--affiliateProgram-circle-bottom-amount-color:#D0A358;' +
          '--affiliateProgram-circle-bottom-total-color:#8EA3C3;' +
          '--affiliateProgram-container-item-commission-desc-color:#8EA3C3;' +
          '--affiliateProgram-swiper-pagination-color:#1184FA;' +
          '--affiliateProgram-swiper-pagination-bullet-inactive-color:#2C394E;';
      }
    },
    // disableZoomOnInputFocus() {
    //   let viewportMeta = document.querySelector('meta[name="viewport"]');
    //   let inputElements = document.querySelectorAll('input, select, textarea');
    //
    //   inputElements.forEach(function(element) {
    //     element.addEventListener('focus', function() {
    //       console.log('add successful ---------------------------')
    //       viewportMeta.setAttribute('content', 'width=device-width,initial-scale=1,maximum-scale=1');
    //     });
    //     element.addEventListener('blur', function() {
    //       viewportMeta.setAttribute('content', 'width=device-width,initial-scale=1');
    //     });
    //   });
    // },
    resize() {
      console.log(window.innerWidth);
      let menuStr = localStorage.getItem('showMenu');
      let showMenu = false;
      if (menuStr) {
        showMenu = menuStr === 'true';
      } else {
        showMenu = true;
      }

      this.topClass = this.$isMobile() ? 'phone' : 'web';
      let sub308 = 308;
      if (this.topClass == 'phone') {
        this.changeLoginPageDiv();
      } else if (window.innerWidth <= 780) {
        this.topClass = 'phone';
      } else if (window.innerWidth <= serverConfig.breakPx[0]) {
        this.topClass = 'web web-960';
        sub308 = 140;
      }
      if (!showMenu) {
        sub308 = 140;
      }
      this.changeLoginPageDiv();
      if (this.topClass == 'phone') {
        document.body.style.height = window.innerHeight + 'px';
        let divs2 = document.getElementsByClassName('body');
        if (divs2 && divs2.length > 0) {
          for (let i = 0; i < divs2.length; i++) {
            divs2[i].style = '';
          }
        }
        let divs3 = document.getElementsByClassName('body2');
        if (divs3 && divs3.length > 0) {
          for (let i = 0; i < divs3.length; i++) {
            divs3[i].style = '';
          }
        }
        return;
      }

      let divs2 = document.getElementsByClassName('body');
      let bodyStyle = '';
      let max_width = window.innerWidth - sub308;
      if (max_width > serverConfig.breakPx[0]) {
        max_width = serverConfig.breakPx[0];
      }
      if (max_width < serverConfig.breakPx[1]) {
        max_width = serverConfig.breakPx[1] - 64;
      }
      bodyStyle = 'max-width:' + max_width + 'px;width:' + max_width + 'px;';
      if (divs2 && divs2.length > 0) {
        for (let i = 0; i < divs2.length; i++) {
          divs2[i].style = bodyStyle;
        }
      }
      let divs3 = document.getElementsByClassName('body2');
      // let body2Style = ''
      max_width = window.innerWidth - sub308;
      if (max_width > serverConfig.breakPx[0]) {
        max_width = serverConfig.breakPx[0];
      }
      if (max_width < serverConfig.breakPx[1]) {
        max_width = serverConfig.breakPx[1] - 64;
      }
      // body2Style = 'max-width:' + max_width + 'px;width:' + max_width + 'px'
      if (divs3 && divs3.length > 0) {
        for (let i = 0; i < divs3.length; i++) {
          divs3[i].style = 'max-width:' + max_width + 'px;width:' + max_width + 'px';
        }
      }
      // this.createCSSSelector('.body2', body2Style)
    },
    changeLoginPageDiv() {
      let log_page = document.getElementsByClassName('login-page');
      if (window.innerWidth < 90 * 16 && log_page.length > 0) {
        if (log_page[0].parentElement && log_page[0].parentElement.parentElement && log_page[0].parentElement.parentElement.parentElement.classList.contains('login-dialog')) {
          log_page[0].style = '';
          log_page[0].getElementsByClassName('left')[0].style = '';
          log_page[0].getElementsByClassName('right')[0].style = '';
          return;
        }
        let width = window.innerWidth;
        log_page[0].style = this.topClass == 'phone' ? '' : 'width:' + width + 'px;';
        log_page[0].getElementsByClassName('left')[0].style = this.topClass == 'phone' ? '' : 'width:' + width * (52.3 / 90) + 'px;';
        log_page[0].getElementsByClassName('right')[0].style = this.topClass == 'phone' ? '' : 'width:' + width * (37.7 / 90) + 'px;';
      }
    },
    async fbPixelInit() {
      let referralCode,
        fbDynamicPixel,
        fbToken,
        kwaiDynamicPixel,
        kwaiToken,
        kwaiClickId,
        adsname = '';
      if (localStorage.getItem('pwaParams')) {
        const params = JSON.parse(localStorage.getItem('pwaParams'));
        // 解析URL参数
        const urlParams = new URLSearchParams(params);
        // 获取referral_code参数的值
        referralCode = urlParams.get('referral_code');
        // 获取fb_dynamic_pixel参数的值
        fbDynamicPixel = urlParams.get('fb_dynamic_pixel');
        // 获取fb_token
        fbToken = urlParams.get('fb_token');
        // 获取kwai_dynamic_pixel参数的值
        kwaiDynamicPixel = urlParams.get('kwai_dynamic_pixel');
        // 获取kwai_token
        kwaiToken = urlParams.get('kwai_token');
        // 获取click_id
        kwaiClickId = urlParams.get('click_id');
        // 获取adsname参数的值
        adsname = urlParams.get('adsname');
        this.isPwa = true;
      } else {
        // 获取当前页面的URL
        const currentUrl = document.location.search;
        // 解析URL参数
        const urlParams = new URLSearchParams(currentUrl);
        // 获取referral_code参数的值
        referralCode = urlParams.get('referral_code');
        // 获取fb_dynamic_pixel参数的值
        fbDynamicPixel = urlParams.get('fb_dynamic_pixel');
        // 获取fb_token
        fbToken = urlParams.get('fb_token');
        // 获取kwai_dynamic_pixel参数的值
        kwaiDynamicPixel = urlParams.get('kwai_dynamic_pixel');
        // 获取kwai_token
        kwaiToken = urlParams.get('kwai_token');
        // 获取click_id
        kwaiClickId = urlParams.get('click_id');
        // 获取adsname参数的值
        adsname = urlParams.get('adsname');
      }

      const appStore = useAppStore();
      const token = getCookieAccessToken();
      const userId = getCookieUserId();
      console.log('pixelId:' + fbDynamicPixel + ' referralCode:' + referralCode + ' adsname:' + adsname + ' fbToken:' + fbToken);
      console.log('kwaiPixelId:' + kwaiDynamicPixel + ' referralCode:' + referralCode + ' adsname:' + adsname + ' kwaiToken:' + kwaiToken + ' kwaiClickId:' + kwaiClickId);
      if (fbDynamicPixel) {
        appStore.fpPixelId = fbDynamicPixel;
      }
      if (fbToken) {
        appStore.fbToken = fbToken;
      }
      if (kwaiDynamicPixel) {
        appStore.kwaiPixelId = kwaiDynamicPixel;
      }
      if (kwaiToken) {
        appStore.kwaiToken = kwaiToken;
      }
      if (kwaiClickId) {
        appStore.kwaiClickId = kwaiClickId;
      }
      if (referralCode) {
        appStore.fpReferral = referralCode;
      }
      if (adsname) {
        appStore.adsname = adsname;
      }

      // kwai
      if(token && kwaiDynamicPixel) {
        // 更新kwaiPixelId和kwaiToken
        const param = {
          kwai_pixel_id: kwaiDynamicPixel,
          kwai_token: kwaiToken,
          click_id: kwaiClickId
        };
        try {
          await updateUserFBPixelIdService(param);
        } catch (error) {
          console.log(error);
        }
      }
      // 上报kwai浏览app事件
      const params = {
        kwai_pixel_id: appStore.kwaiPixelId,
        kwai_token: appStore.kwaiToken,
        click_id: appStore.kwaiClickId,
        event_name: 'contact_view',
      }
      kwaiEventService(params);

      // facebook pixel 当前登录状态
      if (token) {
        // 没有初始化fp
        if (!appStore.fpInit) {
          if (fbDynamicPixel) {
            // 更新pixelId和fbToken
            const param = {
              facebook_pixel_id: fbDynamicPixel,
              fb_token: fbToken
            };
            try {
              await updateUserFBPixelIdService(param);
            } catch (error) {
              console.log(error);
            }
            fpInit(fbDynamicPixel);
            appStore.fpInit = true;
          } else {
            try {
              const response = await userInfoService(userId);
              if (response.data.facebook_pixel_id) {
                fpInit(response.data.facebook_pixel_id);
                appStore.fpInit = true;
              }
            } catch (error) {
              console.log(error);
            }
          }
        }
      } else if (fbDynamicPixel) {
        fpInit(fbDynamicPixel);
        appStore.fpInit = true;
      }
      if (this.isPwa) {
        // 是pwa放入pwa进入埋点
        fpPCustomEvent({
          event_name: 'ViewWinGamePwaPage',
          content_name: 'ViewWinGamePwaPage',
          content_type: 'PWA'
        });
      }
    }
  }
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--page-default-color);
}
html {
  font-family: 'Poppins', sans-serif;
}
body {
  touch-action: pan-x pan-y;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  outline: 0;
}
.win-game-style {
  position: fixed;
  bottom: 9rem;
  right: 2rem;
  z-index: 1450;
  background-color: var(--body-bg-color);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 36px;
  padding: 0;
  width: 2rem;
  height: 2rem;
}
.phone .win-game-style {
  right: 1rem;
}
</style>
