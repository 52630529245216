<script setup>
import { ref, watch } from 'vue';
import wgDialog from '@/components/common/wgDialog'
import Turntable from './components/turntable.vue'
import wgTab from '@/components/common/wgTab.vue';
import scheduleInfo from './components/scheduleInfo.vue';
import { useI18n } from 'vue-i18n';
import { useAppStore } from '@/store/store';
import emitter from '@/assets/tools/eventBus';
import { getHeaderImg } from '@/assets/tools/avatar.js';
import wgButton from '@/components/common/wgButton.vue'
import luckyBonusTable from './components/luckyBonusTable.vue'
import { getTurntableDetail, getTurntableResult, getTurntableReceive } from '@/assets/network/service/turntable'
import { toast } from 'vue3-toastify';
import referralDialog from './components/referralDialog.vue'
import { isMobile } from '@/assets/tools/CommonTool'
import CountDown from './components/CountDown.vue'

defineOptions({
    name: 'turntableDialog'
})

const { t } = useI18n();
const appStore = useAppStore();

const visible = defineModel({ visible: Boolean });

const referralDialogShow = ref(false)

const tipModelShow = ref(false)

const tipModelText = ref('')

const tableShow = ref(false)

const rulesModelShow = ref(false)

const props = defineProps({
    initiativeTab: {
        type: Boolean
    },
    list: {
        type: []
    }
})

// 创建对抽奖组件的引用
const turntableRef = ref(null);

const sections = ref([]);

// 活动列表tab配置
const tab = ref()

// 当前tab信息
const currentTab = ref()

const turntableInfo = ref({})


const getTurnDetailHandle = async (id) => {
    try {
        const detailRes = await getTurntableDetail({
            id: id,
            user_id: appStore.isLogin&&appStore?.userInfo.user_id ? appStore?.userInfo.user_id : null
        })
        if (!detailRes.data) return;
        turntableInfo.value = detailRes.data.data
        sections.value = turntableInfo.value.setting.map(item => {
            return {
                text: item.reward_amount,
                icon: item.icon,
                ...item
            }

        })
    } catch (e) {
        console.error(e)
    }


}

const tabChangeHandle = (info) => {
    currentTab.value = info
    tipModelShow.value = false
    // turntableRef.value.init && turntableRef.value.init()
    getTurnDetailHandle(info.id)
}

watch(visible, (value) => {
    if (value) {
        if (props.initiativeTab) {
            // 过滤出 home_tab 为 true 的项
            tab.value = props.list.filter(item => item.home_tab).map(item => ({
                name: item.activity_name,
                id: item.id
            }));
        } else {
            // 不过滤，返回所有项的 activity_name
            tab.value = props.list.map(item => ({
                name: item.activity_name,
                id: item.id
            }));
        }
        currentTab.value = tab.value[0]
        getTurnDetailHandle(currentTab.value.id)
    }
}, {
    immediate: true
})

watch(() => appStore?.userInfo, (val) => {
    if (val && currentTab.value) {
        getTurnDetailHandle(currentTab.value.id)
    }
}, {
    immediate: true
})



const beforeStartCallback = async () => {
    if (appStore.isLogin && turntableInfo) {

        try {
            const res = await getTurntableResult({
                id: turntableInfo.value.id,
                user_id: appStore?.userInfo.user_id
            })
            if (res.data.code !== 200) {
                toast(res.data.msg, { autoClose: 2000, type: 'error' });
                return;
            }
            const id = res.data.data.id
            const index = sections.value.findIndex(item => {
                return item.id === id
            })
            turntableRef.value.play(index)
        } catch (e) {
            console.log(e)
        }

    } else {
        visible.value = false
        emitter.emit('custom-login', 'login');
    }
}

const turnTableEndCallback = (info) => {
    if (info.reward_type === '1') {
        tipModelText.value = info.currency + info.text
        tipModelShow.value = true
    }
    getTurnDetailHandle(currentTab.value.id);
}


const withdrawClickHandle = async () => {
    try {
        if (appStore.isLogin && turntableInfo) {
            const res = await getTurntableReceive({
                id: currentTab.value.id,
                user_id: appStore?.userInfo.user_id ? appStore?.userInfo.user_id : null
            })
            if (res.data.code !== 200) {
                toast(res.data.msg, { autoClose: 2000, type: 'error' });
                return;
            }
            getTurnDetailHandle(currentTab.value.id)
            toast(t('scheduleInfo_017'));
        } else {
            visible.value = false
            emitter.emit('custom-login', 'login');
        }
    } catch (e) {
        toast(e.response.data.error, { autoClose: 2000, type: 'error' });
        console.error(e.response.data.error)
    }

}

const inviteHandle = () => {
    if (appStore.isLogin) {
        referralDialogShow.value = true
    } else {
        visible.value = false
        emitter.emit('custom-login', 'login');
    }

}

const openLuckyList = () => {
    if (appStore.isLogin && turntableInfo) {
        tableShow.value = true
    } else {
        visible.value = false
        emitter.emit('custom-login', 'login');
    }
}



</script>

<template>
    <div>
        <wg-dialog :show="visible" myClass="!z-[1600] overflow-y-auto">
            <div>
                <luckyBonusTable v-if="tableShow" :id="currentTab.id" @close="() => {
                tableShow = false
            }"></luckyBonusTable>
            <div v-else class="relative">
                <div :class="isMobile() ? 'absolute top-[-3rem]' : 'w-[8rem] absolute left-[-8.2rem]'">
                    <wgTab :direction="isMobile() ? 'horizontal' : 'vertical'" :tab="tab" @change="tabChangeHandle">
                    </wgTab>
                </div>
                <div class="relative w-[24rem] bg-spin-model-bg p-[1rem] bg-cover overflow-y-auto"
                    :class="isMobile() ? 'w-[calc(100vw-3.5rem)] h-[calc(100vh-15rem)]' : 'h-[calc(100vh-10rem)] max-h-[855px]'">
                    <div class="flex justify-between">
                        <div></div>
                        <div class="text-center text-[#fff] font-bold">
                            {{ turntableInfo.turntable_name }}
                        </div>
                        <div class="cursor-pointer" @click="() => {
                            visible = false
                        }">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"
                                fill="none">
                                <path
                                    d="M1.00385 0.33994C1.4571 -0.113312 2.19196 -0.113312 2.64521 0.33994L13.3772 11.072C13.8304 11.5252 13.8304 12.2601 13.3772 12.7133C12.9239 13.1666 12.1891 13.1666 11.7358 12.7133L1.00385 1.98131C0.550598 1.52806 0.550597 0.793191 1.00385 0.33994Z"
                                    fill="#5B7BAB" />
                                <path
                                    d="M13.3773 0.339939C13.8305 0.79319 13.8305 1.52806 13.3773 1.98131L2.64528 12.7133C2.19203 13.1666 1.45717 13.1666 1.00392 12.7133C0.55067 12.2601 0.55067 11.5252 1.00392 11.072L11.7359 0.339939C12.1891 -0.113313 12.924 -0.113313 13.3773 0.339939Z"
                                    fill="#5B7BAB" />
                            </svg>
                        </div>
                    </div>
                    <div class="mt-[1rem]">
                        <scheduleInfo :turntableInfo="turntableInfo" @withdrawClick="withdrawClickHandle">
                        </scheduleInfo>
                    </div>
                    <div class="flex justify-end mt-[1.05rem]">
                        <el-icon color="#AAC4ED" size="1.5rem" class="cursor-pointer" @click="() => {
                            rulesModelShow = true
                        }">
                            <InfoFilled />
                        </el-icon>
                    </div>
                    <Turntable class="mt-[0.22rem]" ref="turntableRef" :sections="sections"
                        @beforeStartCallback="beforeStartCallback" @endCallback="turnTableEndCallback">
                    </Turntable>

                    <div class="text-[#fff] font-bold text-[1.125rem] text-center mt-[2.1rem]">
                        <span v-if="turntableInfo.receive_reward" class="text-[0.875rem] text-wrap flex justify-center">
                            <span v-if="turntableInfo.next_participation_time">
                                <CountDown :targetTimestamp="turntableInfo.next_participation_time"></CountDown> {{
                                    $t('scheduleInfo_024') }}
                            </span>
                            <span v-else>
                                {{ $t('scheduleInfo_023') }}
                            </span>

                        </span>
                        <span v-else>
                            {{ t('scheduleInfo_003') }} {{ turntableInfo?.remaining_spin_chance }} /
                            {{ turntableInfo?.total_spin_chance }}
                        </span>
                    </div>
                    <wgButton v-if="turntableInfo?.support_invite_incr === 1"
                        class="mt-[1rem] text-[#fff] font-bold py-[0.69rem] px-[1rem]" @click="inviteHandle">
                        {{ t('scheduleInfo_018') }}
                    </wgButton>
                    <div class="flex justify-center mt-[2.1rem] gap-4 items-center ">
                        <div class="bg-[#222942] p-[1.05rem] rounded-md h-[5rem] w-[45%]">
                            <div class="mt-[1rem]">
                                <div class="text-[#fff] font-bold text-[0.875rem] text-center">
                                    {{ t('scheduleInfo_004') }}
                                </div>
                                <div class="text-[#FFB355] text-center font-bold text-[0.875rem] mt-[1rem]">
                                    evUSD {{ turntableInfo?.total_receive_amount }}
                                </div>
                            </div>
                        </div>
                        <div class="bg-[#222942] py-[1.05rem] px-[0.58rem] flex h-[5rem] w-[48%]"
                            @click="openLuckyList">
                            <div class="text-[#fff] font-bold text-[0.875rem]"
                                v-if="appStore?.userInfo.profile_image_url">
                                <img :src="getHeaderImg(appStore?.userInfo.profile_image_url)"
                                    class="w-[1.74rem] h-[1.74rem]" />
                            </div>
                            <div>
                                <div class="text-[#fff] text-[0.75rem]">
                                    {{ appStore?.userInfo.name }}
                                </div>
                                <div class="text-[#00D66F] font-bold">
                                    {{ t('scheduleInfo_005') }}
                                </div>
                                <div>
                                    <span class="text-[#80A4DB]">{{ t('scheduleInfo_009') }}{{ ' ' }}</span><span
                                        class="text-[#FFB355]">
                                        evUSD {{ turntableInfo?.receive_amount }}</span>
                                </div>
                            </div>
                            <div class="flex items-center ml-2 cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="18" viewBox="0 0 9 18"
                                    fill="none">
                                    <path opacity="0.6" d="M0.934294 0.569641L8.39331 8.96103L0.934294 17.3524"
                                        fill="#96A5CC" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            

        </wg-dialog>
        <wg-dialog :show="tipModelShow" myClass="!z-[1700]">
            <div class="relative bg-spin-model-bg bg-bottom  bg-cover w-[31.5rem] h-[21rem]">
                <img class="absolute left-[-15rem] top-[-2rem]" src="../../assets//images/spin/spin-bg-left.png" alt="">
                <img class="absolute right-[-10rem] top-[-3rem]" src="../../assets//images/spin/spin-bg-right.png"
                    alt="">
                <div class="relative z-1000">
                    <div class="text-[#fff] font-bold text-center text-[0.94rem] mt-[3rem]">
                        {{ t('scheduleInfo_010') }}
                    </div>
                    <div class="text-[#AAC4ED] text-center text-[1.125rem] mt-[3rem]">
                        {{ t('scheduleInfo_011') }}
                    </div>
                    <div class="text-[#FDE25D] text-center font-bold text-[2.5rem] mt-[3rem]">
                        {{ tipModelText }}
                    </div>
                    <div class="flex justify-center mt-[1.03rem]">
                        <wgButton class="w-[19rem] py-[0.69rem] text-[#fff]" @click="() => {
                            tipModelShow = false
                        }">
                            {{ t('scheduleInfo_012') }}
                        </wgButton>
                    </div>
                </div>


            </div>
        </wg-dialog>
        <wg-dialog :show="rulesModelShow" myClass="!z-[1700]">
            <div class="bg-[#222942] p-[1rem]">
                <div class=" flex justify-end">
                    <div class="cursor-pointer" @click="() => {
                        rulesModelShow = false
                    }">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <path
                                d="M1.00385 0.33994C1.4571 -0.113312 2.19196 -0.113312 2.64521 0.33994L13.3772 11.072C13.8304 11.5252 13.8304 12.2601 13.3772 12.7133C12.9239 13.1666 12.1891 13.1666 11.7358 12.7133L1.00385 1.98131C0.550598 1.52806 0.550597 0.793191 1.00385 0.33994Z"
                                fill="#5B7BAB" />
                            <path
                                d="M13.3773 0.339939C13.8305 0.79319 13.8305 1.52806 13.3773 1.98131L2.64528 12.7133C2.19203 13.1666 1.45717 13.1666 1.00392 12.7133C0.55067 12.2601 0.55067 11.5252 1.00392 11.072L11.7359 0.339939C12.1891 -0.113313 12.924 -0.113313 13.3773 0.339939Z"
                                fill="#5B7BAB" />
                        </svg>
                    </div>
                </div>
                <div class="max-h-[14rem] overflow-y-auto w-[25.5rem]" v-html="turntableInfo.rules">
                </div>
            </div>
        </wg-dialog>
        <referralDialog :show="referralDialogShow" @close="() => { referralDialogShow = false }"></referralDialog>
    </div>
</template>