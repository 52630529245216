<script setup>
// 导入
import { defineProps, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useClipboard } from '@vueuse/core';
import { useAppStore } from '@/store/store';
import { transformedStr, getBrowser, formatDate } from '@/assets/tools/CommonTool.js';
import { toast } from 'vue3-toastify';
import ButtonItem from '@/components/wallet/module/ButtonItem';
import useWalletStore from '@/store/wallet';

// 传参
const props = defineProps({
  row: {
    type: Object
  }
});
// 属性
const { t } = useI18n();
const { copy } = useClipboard({ legacy: true });
const appStore = useAppStore();
const walletStore = useWalletStore();
// 计算属性
// 生命周期
// 方法
const getSymbolIconUrl = computed(() => {
  return walletStore.currencyTokens.find(token => token.symbol === props.row.symbol)?.icon_url || '';
});

const getTableTitle = (type) => {
  if (type == 1) {
    return t('Deposit_details');
  } else if (type == 2) {
    return t('Withdraw_details');
  } else {
    return '';
  }
};

const getTableStatus = (type, status) => {
  if (type == 1) {
    return status;
  } else if (type == 2) {
    if (status == 2 || status == 3) {
      return 0;
    } else if (status == 1) {
      return 1;
    } else if (status == 4 || status == 5 || status == 6) {
      return 2;
    } else {
      return '';
    }
  } else {
    return '';
  }
};
const formatTableStatus = (type, status) => {
  const state = getTableStatus(type, status);
  if (state == 0) {
    return t('Processing');
  } else if (state == 1) {
    return t('success');
  } else if (state == 2) {
    return t('Failure');
  } else {
    return '';
  }
};

const clickTXID = (row) => {
  const browser = getBrowser();
  if (browser == 'Safari') {
    let win = window.open('', '_blank');
    win.location = row.network_url;
  } else {
    window.open(row.network_url, '_blank');
  }
};

const clickPayNow = (row) => {
  const browser = getBrowser();
  if (browser == 'Safari') {
    let win = window.open('', '_blank');
    win.location = row.network_url;
  } else {
    window.open(row.network_url, '_blank');
  }
};
</script>

<template>
  <el-dialog v-model="appStore.txDetailShow" width="500" align-center :close-on-click-modal="false" class="bg-[#121829] text-left">
    <template #header>
      <div class="text-[#fff] text-[14px] font-[700]">{{ getTableTitle(props.row.type).toUpperCase() }}</div>
    </template>
    <div class="relative">
      <div class="bg-[#222942] rounded-[6px] h-[50px] flex justify-center items-center gap-[5px] mb-[15px]">
        <img :src="getSymbolIconUrl" class="w-[17px] h-[17px]" />
        <div class="text-[#fff] text-[14px] font-[700]">{{ `${props.row.amount} ${props.row.symbol}` }}</div>
      </div>
      <div class="flex flex-col gap-[15px]">
        <!-- STATUS -->
        <div class="flex justify-between items-center">
          <div class="text-[#5B7BAB] text-[12px] font-[700]">{{ t('status').toUpperCase() }}</div>
          <div class="text-[#ffffff] text-[14px] font-[400] flex items-center gap-[5px]">
            <el-icon v-if="getTableStatus(props.row.type, props.row.status) == 0" style="color: #e6a23c"><WarningFilled /></el-icon>
            <el-icon v-else-if="getTableStatus(props.row.type, props.row.status) == 1" style="color: #00b555"><SuccessFilled /></el-icon>
            <el-icon v-else-if="getTableStatus(props.row.type, props.row.status) == 2" style="color: #f15a5a"><CircleCloseFilled /></el-icon>
            {{ formatTableStatus(props.row.type, props.row.status) }}
          </div>
        </div>
        <!-- TXID -->
        <div v-if="!props.row.is_fiat" class="flex justify-between items-center">
          <div class="text-[#5B7BAB] text-[12px] font-[700]">TXID</div>
          <div class="text-[#3AA1FF] text-[14px] font-[400] flex items-center gap-[5px] underline cursor-pointer" @click="clickTXID(props.row)">
            {{ transformedStr(props.row.txid, 5, 5, 10, '...') }}
          </div>
        </div>
        <!-- order no -->
        <div v-if="props.row.is_fiat" class="flex justify-between items-center">
          <div class="text-[#5B7BAB] text-[12px] font-[700]">{{ t('order_no').toUpperCase() }}</div>
          <div class="text-[#ffffff] text-[14px] font-[400] flex items-center gap-[5px]">
            {{ transformedStr(props.row.order_id, 5, 5, 10, '...') }}
            <span
              class="iconfont icon-bntn_ContentCopyFilled cursor-pointer !text-[24px] text-[#80A4DB]"
              @click="
                () => {
                  copy(props.row.order_id);
                  toast($t('copy_successfully'), { autoClose: 1000 });
                }
              "
            ></span>
          </div>
        </div>
        <!-- Payment -->
        <div v-if="props.row.is_fiat" class="flex justify-between items-center">
          <div class="text-[#5B7BAB] text-[12px] font-[700]">{{ t('Payment').toUpperCase() }}</div>
          <div class="text-[#ffffff] text-[14px] font-[400] flex items-center gap-[5px]">
            {{ props.row.pay_method }}
          </div>
        </div>
        <!-- Account -->
        <div v-if="props.row.is_fiat" class="flex justify-between items-center">
          <div class="text-[#5B7BAB] text-[12px] font-[700]">{{ t('account').toUpperCase() }}</div>
          <div class="text-[#ffffff] text-[14px] font-[400] flex items-center gap-[5px]">
            {{ props.row.account_no }}
          </div>
        </div>
        <!-- TIME -->
        <div class="flex justify-between items-center">
          <div class="text-[#5B7BAB] text-[12px] font-[700]">{{ t('time').toUpperCase() }}</div>
          <div class="text-[#ffffff] text-[14px] font-[400] flex items-center gap-[5px]">
            {{ formatDate(props.row.create_time) }}
          </div>
        </div>
        <!-- FEE -->
        <div v-if="props.row.type == 2 && !props.row.is_fiat" class="flex justify-between items-center">
          <div class="text-[#5B7BAB] text-[12px] font-[700]">{{ t('fee').toUpperCase() }}</div>
          <div class="text-[#ffffff] text-[14px] font-[400] flex items-center gap-[5px]">
            {{ props.row.fee }}
          </div>
        </div>
        <!-- ADDRESS -->
        <div v-if="props.row.type == 2 && !props.row.is_fiat" class="flex justify-between items-center">
          <div class="text-[#5B7BAB] text-[12px] font-[700]">{{ t('address').toUpperCase() }}</div>
          <div class="text-[#ffffff] text-[14px] font-[400] flex items-center gap-[5px]">
            {{ transformedStr(props.row.address, 5, 5, 10, '...') }}
            <span
              class="iconfont icon-bntn_ContentCopyFilled cursor-pointer !text-[24px] text-[#80A4DB]"
              @click="
                () => {
                  copy(props.row.address);
                  toast($t('copy_successfully'), { autoClose: 1000 });
                }
              "
            ></span>
          </div>
        </div>
      </div>
      <div v-if="props.row.type == 1 && getTableStatus(props.row.type, props.row.status) == 0" class="w-full pt-[15px] mt-[15px]" style="border-top: 1px solid #636b7429">
        <ButtonItem :btnText="t('pay_now').toUpperCase()" @click="clickPayNow(props.row)" />
      </div>
    </div>
  </el-dialog>
</template>

<style scoped></style>
