import { defineStore } from 'pinia';
import { ref } from 'vue';
import { getTGDrainageConfigService } from '@/assets/network/service/home';

export const storeKey = {
  app: 'app'
};

export const useAppStore = defineStore(storeKey.app, {
  state: () => ({
    fpInit: false,
    fpReferral: '',
    fpPixelId: '',
    fbToken: '',
    kwaiPixelId: '',
    kwaiToken: '',
    kwaiClickId: '',
    referralCode: '',
    openCustomer: false,
    reloadFlag: 0,
    customerShowType: '', // Original Intercom
    adsname: '',
    isLogin: false,
    showDeposit: false,
    webSocketData: {
      myBets: [],
      publicBets: []
    },
    txDetailShow: false,
    userInfo: {},
    homeTopBanner: null,
    sportsShow: false,
  }),
  actions: {
    addMyBets(data) {
      this.webSocketData.myBets.push(data);
    },
    clearMyBets() {
      this.webSocketData.myBets = [];
    },
    addPublicBets(data) {
      this.webSocketData.publicBets = data;
    },
    clearPublicBets() {
      this.webSocketData.publicBets = [];
    },
    openCustomerPopup() {
      this.openCustomer = true;
    },
    closeCustomerPopup() {
      this.openCustomer = false;
    },
    switchCustomerShowType(type) {
      this.customerShowType = type;
    },
    addUserInfo(userInfo) {
      this.userInfo = userInfo;
    },
    clearUserInfo() {
      this.userInfo = {};
    },
    addHomeTopBanner(homeTopBanner) {
      this.homeTopBanner = homeTopBanner;
    },
  }
});

export const useTGStore = defineStore('TG', () => {
  const show = ref(false);
  const config = ref(null);

  const updateShow = (bool) => {
    show.value = bool;
  };

  const getConfigService = async (_type) => {
    const res = await getTGDrainageConfigService({ type: _type || 'INDEX' });
    if (res.data.code == 200 && res.data.tg_drainage_config.items.length > 0) {
      config.value = res.data.tg_drainage_config.items[0];
      if (config.value.switch && config.value.display_time > 0) {
        updateShow(true);
      }
    } else {
      config.value = null;
    }
  };

  return {
    show,
    config,
    updateShow,
    getConfigService
  };
});

export const useLoginStore = defineStore('Login', () => {
  const show = ref(false);
  const actType = ref('login');
  const isVerifyEmailShow = ref(false);

  const updateVerifyEmailShow = (val) => {
    isVerifyEmailShow.value = val;
  };

  const updateShow = (bool) => {
    show.value = bool;
  };

  const updateActType = (config) => {
    actType.value = config;
  };

  return {
    show,
    actType,
    updateShow,
    updateActType,
    isVerifyEmailShow,
    updateVerifyEmailShow
  };
});
